import { clientTransofmer, getAvatarUrl } from '@/services/transformers/clientTransformer'

const baseURL = 'agent_clients'

export default {
  /**
   * Get client list.
   *
   * @param params
   * @returns Promise
   */
  get(params = {}) {
    return axios.get(baseURL, {
      params,

      transformResponse(data) {
        return data.items.map(clientTransofmer)
      }
    }).then(response => response.data)
  },

  /**
   * Get specific client.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  find(id, params = {}) {
    return axios.get(`${baseURL}/${id}`, {
      params,

      transformResponse(data) {
        data.client.avatarUrl = getAvatarUrl(data.client, 'origin')
        data.client.avatarPreviewUrl = getAvatarUrl(data.client, 'small')

        return data
      }
    }).then(response => response.data)
  },

  /**
   * Create and attach new client.
   *
   * @param data
   * @returns Promise
   */
  store(data) {
    return axios.post(baseURL, data).then(response => response.data)
  },

  /**
   * Attach existing client by id.
   *
   * @param data
   * @returns Promise
   */
  storeById(data) {
    return axios.post(baseURL, data).then(response => response.data)
  },

  /**
   * Update client info and notes.
   *
   * @param id
   * @param data
   * @param notes
   * @returns Promise
   */
  update({ id, data, notes }) {
    return axios.patch(`profiles/${id}`, data).then(() => {
      return axios.patch(`${baseURL}/${id}`, { notes })
    })
  },

  /**
   * Detach client.
   *
   * @param id
   * @returns Promise
   */
  remove(id) {
    return axios.delete(`${baseURL}/${id}`).then(response => response.data)
  },

  /**
   * Store client's avatar.
   *
   * @param id
   * @param data
   * @returns Promise
   */
  storeAvatar(id, data) {
    return axios.post(`/profiles/${id}/avatar`, data)
  },

  /**
   * Get client gallery.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  getGallery(id, params = {}) {
    return axios.get(`profiles/${id}/gallery_images`, {
      params
    }).then(response => response.data.items)
  },

  /**
   * Store new image in client's gallery.
   *
   * @param id
   * @param image
   * @param params
   * @returns Promise
   */
  storeGalleryImage({id, image}, params = {}) {
    return axios.post(`profiles/${id}/gallery_images`, image, {
      params
    }).then(response => response.data[0])
  },

  /**
   * Remove client's gallery image.
   *
   * @param clientId
   * @param imageId
   * @returns Promise
   */
  removeGalleryImage({clientId, imageId}) {
    return axios.delete(`profiles/${clientId}/gallery_images/${imageId}`).then(response => response.data)
  },

  /**
   * Invite transient client.
   *
   * @param clientId
   * @returns Promise
   */
  invite(clientId, inviteType) {
    let data = {
      invite_type: inviteType,
      credentials: {
        client_id: clientId
      }
    }

    return axios.post('invites', JSON.stringify(data)).then(response => response.data)
  }
}