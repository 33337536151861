<template>
  <base-modal class="avatar-uploader-modal"
              :show="show"
              @close="close">

    <form-avatar-uploader :user="user"
                          @avatarUploaded="onAvatarUploaded"/>
  </base-modal>
</template>

<script>
  import FormAvatarUploader from '@/components/Forms/FormAvatarUploader/FormAvatarUploader.vue'

  export default {
    components: {
      FormAvatarUploader
    },

    props: {
      show: {
        required: false,
        type: Boolean,
        default: false
      },

      user: {
        required: false,
        type: Object
      }
    },

    methods: {
      onAvatarUploaded(avatar = null, avatarData = null) {
        this.setAvatarUploadState({ avatar, avatarData })
      },

      close() {
        this.$emit('close')
      },

      ...mapActions('system', [
        'setAvatarUploadState'
      ])
    }
  }
</script>

