export const clientTransofmer = client => {
  return Object.assign({}, client.client, {
    id: client.client.user_id,
    fullName: client.client.full_name,
    email: client.client.email,
    phoneNumber: client.client.phone_number,
    avatarUrl: getAvatarUrl(client.client, 'origin'),
    avatarPreviewUrl: getAvatarUrl(client.client, 'small'),
    transient: client.client.transient
  })
}

export const getAvatarUrl = (client, pictureSize) => {
  return client.avatar ? client.avatar.slices_info.find(info => {
    return info.title === pictureSize
  }).url : null
}