<template>
  <div class="avatar-uploader-wrapper">
    <div v-if="$mq === 'desktop'"
         class="avatar-uploader"
         :style="style"
         @click="showModal = true">
      <base-icon icon="makePhoto"
                 width="40px"
                 height="40px"/>
    </div>

    <router-link v-else
                 class="avatar-uploader"
                 :to="{ name: 'uploadAvatar', params: uploadAvatarPageParams }"
                 :style="style">
      <base-icon icon="makePhoto"
                 width="40px"
                 height="40px"/>
    </router-link>

    <avatar-uploader-modal :show="showModal"
                           :user="user"
                           @close="showModal = false"/>
  </div>
</template>

<script>
  import AvatarUploaderModal from './AvatarUploaderModal.vue'

  export default {
    components: {
      AvatarUploaderModal
    },

    props: {
      user: {
        required: false,
        type: Object
      }
    },

    data() {
      return {
        avatar: null,
        showModal: false
      }
    },

    methods: {
      onAvatarUploaded(avatar, avatarData) {
        this.avatar = avatar
        this.$emit('avatarUploaded', avatarData)

        this.showModal = false
        this.clearAvatarUploadState()
      },

      ...mapActions('system', [
        'clearAvatarUploadState'
      ])
    },

    computed: {
      style() {
        let avatarUrl = this.user ? this.user.avatarPreviewUrl || this.user.avatarUrl : this.avatar

        return {
          background: avatarUrl ? `url(${avatarUrl}) center center / contain no-repeat` : 'none'
        }
      },

      uploadAvatarPageParams() {
        return this.user ? { userId: this.user.user_id } : {}
      },

      ...mapGetters('system', {
        avatarUploadState: 'getAvatarUploadState'
      })
    },

    watch: {
      'avatarUploadState.uploaded': {
        handler(uploaded) {
          if (!uploaded) {
            return
          }

          this.onAvatarUploaded(this.avatarUploadState.avatar, this.avatarUploadState.avatarData)
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss" scoped>


  .avatar-uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.3);
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      box-shadow:inset 0 0 0 2000px rgba($primary-color, 0.5);
    }
  }
</style>
