import AvatarUploader from '@/components/Shared/AvatarUploader/AvatarUploader.vue'
import clientService from '@/services/queries/clientQueries'
import { parseNumber } from 'libphonenumber-js'
import PhoneInput from '@/components/Shared/PhoneInput.vue'

export default {
  components: {
    AvatarUploader,
    PhoneInput
  },

  props: {
    client: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      clientProfile: null,
      notes: '',

      selectedFigureType: null,
      selectedGender: null,
      selectedClothingSizes: [],
      selectedClothingSizesForMetrics: {},

      genders: this.$t('genders'),

      phoneNumberValidationRules: {
        valid_phone: null,
        phonenumber_unique: null
      },

      loading: false
    }
  },

  created() {
    this.setFormData()
  },

  methods: {
    setFormData() {
      this.setClientProfile()

      this.setSelectedGender()
      this.setSelectedClothingSizes()

      this.selectedFigureType = this.getFigureTypeById(this.clientProfile.figure_type_id)
      this.notes = this.client.notes
    },

    setClientProfile() {
      let necessaryFields = ['full_name', 'email', 'phone_number', 'phone_number_country_code', 'height', 'gender',
        'figure_type_id', 'clothing_sizes']

      this.clientProfile = {
        ..._.pick(this.client.client, necessaryFields),
        clothing_sizes: this.client.client.clothing_sizes
      }

      if (this.clientProfile.phone_number) {
        let phoneNumberCountryCode = parseNumber(this.clientProfile.phone_number).country

        this.setPhoneNumberCountryCode(phoneNumberCountryCode)
      }
    },

    setPhoneNumberCountryCode(countryCode) {
      this.phoneNumberValidationRules = {
        valid_phone: countryCode,
        phonenumber_unique: countryCode
      }

      this.clientProfile.phone_number_country_code = countryCode
    },

    setSelectedGender() {
      this.selectedGender = this.genders.find(gender => {
        return gender.value.toLowerCase() === this.clientProfile.gender.toLowerCase()
      })
    },

    setSelectedClothingSizes() {
      this.selectedClothingSizes = this.clientProfile.clothing_sizes

      this.filteredClothingSizeMetrics.forEach(metric => {
        this.selectedClothingSizesForMetrics[metric.clothing_size_metric_id] = this.selectedClothingSizes.find(size => {
          return size.clothing_size_metric_id === metric.clothing_size_metric_id
        })
      })
    },

    clearFormSelectedBodyData() {
      this.selectedFigureType = null
      this.selectedClothingSizes = []
      this.selectedClothingSizesForMetrics = {}
    },

    updateClientProfile() {
      this.loading = true

      let promises = []

      Object.values(this.$refs).forEach(ref => {
        promises.push(ref.validate())
      })

      Promise.all(promises).then(results => {
        let notValid = results.some(success => {
          return !success
        })

        if (notValid) {
          this.loading = false

          return
        }

        let data = JSON.stringify(this.getCompleteFormData())

        clientService.update({
          id: this.client.client_id,
          data,
          notes: this.notes
        }).then(response => {
          this.$emit('clientUpdated')

          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      })
    },

    getCompleteFormData() {
      let completeFormData = _.cloneDeep(this.clientProfile)

      completeFormData.clothing_sizes = []

      if (this.selectedClothingSizes.length > 0) {
        this.selectedClothingSizes.forEach(clothingSize => {
          completeFormData.clothing_sizes.push(clothingSize.clothing_size_id)
        })
      }

      if (this.selectedGender) {
        completeFormData.gender = this.selectedGender.value
      }

      completeFormData.figure_type_id = this.selectedFigureType ? this.selectedFigureType.figure_type_id : null

      return completeFormData
    },

    onSelectClothingSize(value) {
      this.selectedClothingSizes.forEach((clothingSize, index) => {
        if (clothingSize.clothing_size_metric_id === value.clothing_size_metric_id) {
          this.selectedClothingSizes.splice(index, 1);
        }
      })

      this.selectedClothingSizes.push(value)
    }
  },

  computed: {
    ...mapGetters('handbooks', [
      'getFigureTypeById'
    ]),

    filteredFigureTypes() {
      return this.$store.state.handbooks.figureTypes.filter(figureType => {
        return figureType.gender.toLowerCase() === this.selectedGender.value.toLowerCase()
      })
    },

    filteredClothingSizeMetrics() {
      return this.$store.state.handbooks.clothingSizeMetrics.filter(clothingSizeMetric => {
        return clothingSizeMetric.gender.toLowerCase() === this.selectedGender.value.toLowerCase()
      })
    }
  },

  watch: {
    client() {
      this.setFormData()
    }
  },
}
